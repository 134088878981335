<template>
  <div class="">
    <Card class="p-m-2">
      <template #title> Rollerin Yetki Yerleri </template>
      <template #content>
        <div class="p-grid p-fluid p-formgrid">
          <div class="p-field p-col-3">
            <div class="card flex justify-content-center">
              <Listbox v-model="SelectedRol" class="w-full md:w-14rem" dataKey="Rol_rowid" :options="Roller" optionLabel="RolAd"
                placeholder="Rol Seç .." :maxSelectedLabels="1" listStyle="height:270px"
                :virtualScrollerOptions="{ itemSize: 5 }" filter />
              <Button label="Getir" @click="butonclick(data)" />
            </div>
          </div>
          <div class="p-field p-col-9">
            <Card class="p-m-2">
              <template #content>

                <DataTable v-if="DataTable_render" :columns="columns" :data="YetkiYerleriListesi"
                  v-on:set-child-data="getDataChild" />

              </template>

            </Card>
          </div>
        </div>
      </template>
    </Card>


    <Dialog v-model:visible="YetkiModal" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '25vw' }"
      :modal="true">
      <template #header>
        <h4>Yetki Değiştir</h4>
      </template>

      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-6">

          <Button :disabled="YetkilendirButton" label="Yetkilendir" icon="pi pi-refresh"
            class="p-button-sm p-button-success" @click="this.Yetkilendir()" />
        </div>
        <div class="p-field p-col-6">

          <Button :disabled="YetkiKaldirButton" label="Yetki Kaldır" icon="pi pi-replay"
            class="p-button-sm p-button-warning" @click="this.YetkiKaldir()" />
        </div>

      </div>
      <template #footer>

        <Button label="Kapat" icon="pi pi-times" class="p-button-sm p-button-danger" @click="this._YetkiModal()" />
      </template>
    </Dialog>

  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import GlobalServis from "../../../../services/GlobalServis";
import DataTable from "../../DataTable/DataTable.vue";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      YetkilendirButton: false,
      YetkiKaldirButton: false,
      //
      YetkiModal: false,
      DataTable_render: true,
      //
      Roller: [],
      SelectedRol: null,
      SelectedRol_rowid: 1,
      //
      YetkiYerleriListesi: [],
      YetkiYeri_rowid: null,
      //
      columns: [
        { field: "Ad", header: "Yetki Yeri Adı" },
      ],
    };
  },
  methods: {
    butonclick(data) {

      this.SelectedRol_rowid = this.SelectedRol.Rol_rowid
      this._GetRolYetkiYerleri();
    },
    InitService() {

      this.SelectedRol = {
        RolAd: "Admin",
        Rol_rowid: 1
      }

      GlobalServis.GlobalServis("GET", "GetRolTablosu", "").then((res) => {
        if (res.status == 200) {
          this.Roller = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    _GetRolYetkiYerleri() {
      let data = {
        Rol_rowid: this.SelectedRol_rowid
      }
      GlobalServis.GlobalServis("POST", "GetRolYetkiYeriTablosu", data).then((res) => {
        if (res.status == 200) {
          this.YetkiYerleriListesi = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    _UpdateRolYetkiYerleri(Durum) {
      let data = {
        Rol_rowid: this.SelectedRol_rowid,
        YetkiYeri_rowid: this.YetkiYeri_rowid,
        Durum: Durum
      }
      GlobalServis.GlobalServis("POST", "UpdateRolYetki", data).then((res) => {
        if (res.status == 200) {
          this.YetkiYerleriListesi = res.data;
          this.$toast.add({
            severity: "success",
            summary: "Başarılı",
            detail: "Yetki işlemi tamamlanmıştır.",
            life: 3000,
          });
          this._YetkiModal();
          this.Refresh();
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    getDataChild(getdata_child) {
      this.YetkiPopUpDurumu = false;
      this.YetkiModal = !this.YetkiModal;
      this.YetkiYeri_rowid = getdata_child.data.YetkiYeri_rowid

      if (getdata_child.data.Durum == "Yetkili") {
        this.YetkilendirButton = true;
        this.YetkiKaldirButton = false;
      }
      else if (getdata_child.data.Durum == "Yetkisiz") {
        this.YetkilendirButton = false;
        this.YetkiKaldirButton = true;
      }
    },
    _YetkiModal() {
      this.YetkiModal = !this.YetkiModal;
      if (!this.YetkiModal) {
        // this.resetForm();
      }
    },
    Yetkilendir() {
      this._UpdateRolYetkiYerleri("0");
    },
    YetkiKaldir() {
      this._UpdateRolYetkiYerleri("1");
    },
    Refresh() {
      this.DataTable_render = false;
      this.$nextTick(() => {
        // this.InitServis();
        this._GetRolYetkiYerleri();
        this.DataTable_render = true;
      });
    },
  },
  mounted() {
    this.InitService();
    this._GetRolYetkiYerleri();

  },
  components: {
    DataTable
  },
};
</script>

<style lang="scss" scoped>
.top-button-area .p-button.yeni {
  background: linear-gradient(to left,
      var(--purple-600) 50%,
      var(--purple-700) 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: var(--purple-700);
}

.top-button-area .p-button.yeni:hover {
  background-position: left bottom;
}

.top-button-area .p-button.yeni i {
  background-color: var(--purple-700);
}

.top-button-area .p-button.yeni:focus {
  box-shadow: 0 0 0 1px var(--purple-400);
}
</style>
